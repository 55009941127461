import { jsPDF as JSPDF } from "jspdf";
import XLSX from "xlsx";
import autoTable from "jspdf-autotable";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { formatEmpty } from "@/utils/filterUtils";
import moment from "moment";
const exportFileDownload = {
  mixins: [datesHandlerMixin],
  methods: {
    exp__Download: function(
      data,
      dataColumns,
      fileType,
      fileName = "",
      columnsWidth = "auto"
    ) {
      if (fileType === "pdf") {
        this.exp__pdfDownload(data, dataColumns);
      } else if (fileType === "20column_pdf") {
        // for more than 20 columns table pdf download
        this.exp__20column_pdfDownload(data, dataColumns, columnsWidth);
      } else {
        this.exp__xcelDownload(data, dataColumns, fileName, fileType);
      }
    },

    exp__getFormattedDataForDownload(data, columns) {
      return data.map(item => {
        return columns.reduce((acc, curr) => {
          if (curr.skipColumn) {
            return acc;
          }
          switch (this.$commonUtils.getType(curr.dataKey)) {
            case "function":
              acc[curr.name] = curr.dataKey(item);
              break;
            default:
              acc[curr.name] = this.$lodash.get(
                item,
                curr.dataKey,
                curr.emptyValue
              );
          }
          if (acc[curr.name] && curr.formatters.length) {
            acc[curr.name] = curr.formatters.reduce((updtValue, formatter) => {
              switch (formatter.method) {
                case "cultivation_date_format":
                  if (this.dhm__dateUtilsLib.isValid(new Date(updtValue))) {
                    return this.dhm__formatByTZOnUTCISOWithLocale(
                      updtValue,
                      ...formatter.params
                    );
                  }
                  break;
              }
              return updtValue;
            }, acc[curr.name]);
          }
          acc[curr.name] = formatEmpty(
            acc[curr.name],
            curr.showSymbolForZero,
            curr.emptyValue,
            curr.canBeNegative === undefined ? false : curr.canBeNegative
          );
          return acc;
        }, {});
      });
    },
    exp__getFormattedRawDataForDownload(data, columns) {
      return data.map(item => {
        return columns.reduce((acc, curr) => {
          if (curr.skipColumn) {
            return acc;
          }
          switch (this.$commonUtils.getType(curr.dataKey)) {
            case "function":
              acc[curr.name] = curr.dataKey(item);
              break;
            default:
              if (curr.name === "timestamp") {
                const utcDateTime = new Date(item[0]).toUTCString();
                const formattedDateTime = moment
                  .utc(utcDateTime)
                  .format("YYYY-MM-DD HH:mm:ss");
                acc[curr.name] = item[0] ? formattedDateTime : curr.emptyValue;
              } else if (curr.name === "signature") {
                acc[curr.name] = item[2] ? item[2] : curr.emptyValue;
              } else {
                acc[curr.name] = item[1] ? item[1] : curr.emptyValue;
              }
          }
          return acc;
        }, {});
      });
    },
    exp__20column_pdfDownload: function(data, columns, columnsWidth) {
      const pdfData = this.exp__getFormattedDataForDownload(data, columns);
      const filteredColumns = columns
        .filter(x => !x.skipColumn)
        .map(x => {
          return {
            header: x.header,
            dataKey: x.name
          };
        });
      const doc = new JSPDF(
        {
          orientation: "l",
          unit: "mm",
          format: "a2",
          compress: true,
          fontSize: 8,
          lineHeight: 1,
          autoSize: true,
          printHeaders: true
        },
        "",
        "",
        ""
      );
      autoTable(doc, {
        margin: { right: 5, left: 5 },
        tableWidth: "auto",
        styles: {
          fontSize: 9,
          cellWidth: columnsWidth
        },
        body: pdfData,
        columns: filteredColumns
      });
      doc.save(this.getCurrUserLocation.name);
    },
    exp__pdfDownload: function(data, columns) {
      const pdfData = this.exp__getFormattedDataForDownload(data, columns);
      const filteredColumns = columns
        .filter(x => !x.skipColumn)
        .map(x => {
          return {
            header: x.header,
            dataKey: x.name
          };
        });
      const doc = new JSPDF("landscape");
      autoTable(doc, {
        margin: { right: 10, left: 10 },
        tableWidth: "auto",
        styles: {
          fontSize: 9,
          cellWidth: "auto"
        },
        body: pdfData,
        columns: filteredColumns
      });
      doc.save(this.getCurrUserLocation.name);
    },
    exp__xcelDownload: function(data, columns, fileName, fileType = "xlsx") {
      let xlData = [];
      if (fileType === "rawData_xcel") {
        xlData = this.exp__getFormattedRawDataForDownload(data, columns);
      } else {
        xlData = this.exp__getFormattedDataForDownload(data, columns);
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData, {
        origin: "A2",
        skipHeader: true
      });
      ws["!cols"] = columns.map(x => {
        return { width: x.xcelcolumnWidth };
      });
      // ws["!autofilter"] = { ref: "A1:C4" }; // filter purpose
      XLSX.utils.sheet_add_aoa(ws, [
        columns.filter(x => !x.skipColumn).map(x => x.header)
      ]);
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(
        wb,
        (this.getCurrUserLocation?.name
          ? this.getCurrUserLocation.name
          : fileName || "PM_Stats") + ".xlsx",
        {
          type: "binary",
          cellDates: true
        }
      );
    }
  }
};

export default exportFileDownload;
